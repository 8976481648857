import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { FaceAccreditation } from "@/core/accreditation/domain/accreditation";
import {
  DgBadge,
  DgButton,
  DgColumn,
  DgGridContainer,
  DgImage,
  DgRow,
  DgSeparator,
  DgText,
  DgTextButton,
} from "@dasgate/uikit";
import { CredentialDate } from "@/ui/Profile/_components/CredentialDate";

@Component({
  components: {
    DgColumn,
    DgRow,
    DgText,
    DgGridContainer,
    DgTextButton,
    DgButton,
    DgBadge,
    DgImage,
    CredentialDate,
    DgSeparator,
  },
})
export default class FaceAccreditationDetail extends Vue {
  @Prop({ required: true }) public accreditation!: FaceAccreditation;

  @Emit() onGoBack() {
    return;
  }

  @Emit() onRevoke() {
    return this.accreditation.id;
  }
}
