import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { isAccreditationValid, QrAccreditation } from "@/core/accreditation/domain/accreditation";
import { DgBox, DgButton, DgColumn, DgRow, DgSeparator, DgText } from "@dasgate/uikit";
import { CredentialDate } from "@/ui/Profile/_components/CredentialDate";
import { CredentialExpiredLabel } from "@/ui/Profile/_components/CredentialExpiredLabel";

@Component({
  components: { CredentialExpiredLabel, DgBox, DgColumn, DgSeparator, DgRow, DgText, DgButton, CredentialDate },
})
export default class QrAccreditationCard extends Vue {
  @Prop({ required: true }) public accreditation!: QrAccreditation;

  get isValid() {
    return isAccreditationValid(this.accreditation);
  }

  @Emit() onGoToDetail() {
    return this.accreditation.id;
  }

  @Emit() onRefresh() {
    return this.accreditation.id;
  }
}
