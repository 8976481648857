import { render, staticRenderFns } from "./CredentialExpiredLabel.vue?vue&type=template&id=437be76a&scoped=true&"
import script from "./CredentialExpiredLabel.ts?vue&type=script&lang=ts&"
export * from "./CredentialExpiredLabel.ts?vue&type=script&lang=ts&"
import style0 from "./CredentialExpiredLabel.scss?vue&type=style&index=0&id=437be76a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "437be76a",
  null
  
)

export default component.exports