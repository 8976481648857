import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import moment from "moment";
import { GetUserDetailResponse } from "@/legacy/features/portal/models/portal.models";
import { DocumentOcrData, OnboardingInfo, ProcessedImageData, UserImages } from "@/legacy/models/shared";
import { DgAvatar, DgBox, DgColumn, DgFlex, DgGridContainer, DgText, DgImageMagnifier } from "@dasgate/uikit";
import { UserDetailField } from "../UserDetailField";

@Component({
  components: { DgColumn, DgBox, DgText, DgGridContainer, UserDetailField, DgAvatar, DgFlex, DgImageMagnifier },
})
export default class PortrayalInfo extends Vue {
  @Prop({ required: true }) public user!: GetUserDetailResponse;
  @Prop({ required: true }) public documentData!: DocumentOcrData;
  @Prop({ required: true }) public imageData!: ProcessedImageData;
  @Prop({ required: true }) public images!: UserImages;
  @Prop({ required: true }) public documentInfo!: boolean;
  @Prop({ required: true }) public onboardingInfo!: OnboardingInfo;

  public formatDate(date: Date, format: string) {
    return moment(date).format(format);
  }

  public formatOcrFields(ocrField: string, modifiedField: string): string {
    if (modifiedField === ocrField) {
      return ocrField;
    } else if (modifiedField && modifiedField !== "" && modifiedField !== ocrField) {
      return modifiedField + " (" + this.$t("common.onboarding.register.fields.ocr") + " " + ocrField + ")";
    } else {
      return ocrField;
    }
  }

  public formatOcrDateFields(ocrField: string, modifiedField: string): string {
    const dateFormat = this.$t("tenant.dateFormat") as string;
    ocrField = moment(Object(ocrField)).utc().format(dateFormat);
    modifiedField = moment(Object(modifiedField)).utc().format(dateFormat);
    return this.formatOcrFields(ocrField, modifiedField);
  }

  public get selfieVsPhotoScore(): string {
    if (this.imageData.selfie === -1) {
      return "indeterminate";
    }
    return this.imageData.selfieOk ? "passing" : "failing";
  }

  public get documentScore(): string {
    if (this.imageData.document === -1) {
      return "indeterminate";
    }
    return this.imageData.documentOk ? "passing" : "failing";
  }

  public get ocrIntegrityScore(): string {
    if (this.imageData.dataValidationOk === undefined) {
      return "indeterminate";
    }
    return this.imageData.dataValidationOk ? "passing" : "failing";
  }
}
