import Component from "vue-class-component";
import {
  Accreditation,
  AccreditationType,
  FaceAccreditation,
  QrAccreditation,
} from "@/core/accreditation/domain/accreditation";
import { QrAccreditationDetail } from "./QrAccreditationDetail";
import { FaceAccreditationDetail } from "./FaceAccreditationDetail";
import { AccreditationsLoading } from "@/ui/Profile/_components/AccreditationsLoading";
import { DgGridContainer, DgGridContent } from "@dasgate/uikit";
import { Printd } from "printd";
import { executorResult } from "@/ui/_utils/executor";
import Vue from "vue";

@Component({
  components: { FaceAccreditationDetail, QrAccreditationDetail, AccreditationsLoading, DgGridContainer, DgGridContent },
})
export default class AccreditationDetail extends Vue {
  private print: Printd = new Printd();

  public accreditationResult = executorResult<Accreditation>();

  mounted() {
    this.$execute(() => this.$container.accreditationUseCases.get(this.$route.params.accreditationId), {
      resultRef: this.accreditationResult,
    });
  }

  get qrAccreditation(): QrAccreditation | undefined {
    return this.accreditationResult.data?.type === AccreditationType.Qr ? this.accreditationResult.data : undefined;
  }

  get faceAccreditation(): FaceAccreditation | undefined {
    return this.accreditationResult.data?.type === AccreditationType.Face ? this.accreditationResult.data : undefined;
  }

  revoke(accreditationId: string) {
    this.$execute(() => this.$container.accreditationUseCases.revoke(accreditationId));
  }

  goBack() {
    this.$router.go(-1);
  }

  printQr(image: string) {
    const img = document.createElement("img");
    img.src = image;
    this.print.print(img, ["img { width: 60%; margin-left: auto; margin-right: auto; display: block }"]);
  }
}
