import Vue from "vue";
import Component from "vue-class-component";
import { DgBox, DgColumn, DgImage, DgText } from "@dasgate/uikit";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    DgBox,
    DgColumn,
    DgImage,
    DgText,
  },
})
export default class Card extends Vue {
  @Prop({ required: true }) public title!: string;
  @Prop({ required: true }) public description!: string;
  @Prop({ required: true }) public image!: string;

  public onClick() {
    this.$emit("on-click");
  }

  get content() {
    return require("@/assets/images/portalmanagement_onboarding.png");
    // return require('@/assets/images/img_system_error.svg');
  }
}
