import Component from "vue-class-component";
import { DgColumn, DgGridContainer, DgModal, DgSearch, DgText, DgWindowSizeMixin } from "@dasgate/uikit";
import { List } from "@/ui/_components/List";
import { AccountInfoItem } from "./_components/AccountInfoItem";
import { ExternalUserInfo, GetUsersRequest } from "@/legacy/features/portal/models/portal.models";
import { PagedResult } from "@/legacy/models/shared";
import { RouteNames } from "@/ui/_router";
import { getSupportedDocs } from "@/legacy/features/onboarding/models/onboarding.models";

@Component({
  components: {
    AccountInfoItem,
    DgGridContainer,
    DgColumn,
    DgModal,
    DgText,
    DgSearch,
    List,
  },
})
export default class PortalAccounts extends DgWindowSizeMixin {
  public users: ExternalUserInfo[] = [];
  public userToCompleteOnboarding = {
    id: "",
    name: "",
    email: "",
  };
  public confirmationOpened = false;
  public filterdata = {
    filter: "",
    pageSize: 10,
    pageIndex: 0,
    bookmark: "",
    orderby: "date_register",
    sorted: "DESC",
  } as GetUsersRequest;
  public data: PagedResult<ExternalUserInfo> = {
    bookmark: "",
    pageSize: 10,
    totalPages: -1,
    totalItems: -1,
    items: [],
  };
  public bookmarks = [""];
  public awaitingSearch = false;
  public loading = false;

  public async mounted() {
    this.restoreSearch();
    await this.getUsers();
  }

  public get hasMoreItems(): boolean {
    return this.filterdata.pageIndex < this.data.totalPages - 1;
  }

  public get hasLessItems(): boolean {
    return this.filterdata.pageIndex > 0;
  }

  public get isFirstPage(): boolean {
    return this.filterdata.pageIndex === 0;
  }

  public get isLastPage(): boolean {
    return this.filterdata.pageIndex === this.data.totalPages - 1;
  }

  public nextPage(): void {
    if (this.hasMoreItems) {
      this.filterdata.pageIndex = this.filterdata.pageIndex + 1;
      this.getUsers();
    }
  }

  public backPage(): void {
    if (this.hasLessItems) {
      this.filterdata.pageIndex = this.filterdata.pageIndex - 1;
      this.getUsers();
    }
  }

  public firstPage(): void {
    if (!this.isFirstPage) {
      this.filterdata.pageIndex = 0;
      this.getUsers();
    }
  }

  public lastPage(): void {
    if (!this.isLastPage) {
      this.filterdata.pageIndex = this.data.totalPages;
      this.getUsers();
    }
  }

  public search() {
    if (!this.awaitingSearch) {
      setTimeout(() => {
        this.searchReset();
        this.getUsers();
        this.awaitingSearch = false;
      }, 500);
    }
    this.awaitingSearch = true;
  }

  public confirmCompleteOnboarding(id: string): void {
    const user = this.data.items.filter(item => item.id === id)[0];
    let name = "";
    if (user.firstName) {
      name = user.firstName + " " + user.lastName;
    } else if (user.email) {
      name = user.email;
    } else {
      name = user.username;
    }
    const email = user.email;
    this.userToCompleteOnboarding = {
      id,
      name,
      email,
    };
    this.confirmationOpened = true;
  }

  public async completeOnboarding(id: string): Promise<void> {
    const user = this.data.items.filter(item => item.id === id)[0];
    let saloon = "";
    if (this.$appStore.getters.userLocation && this.$appStore.getters.userLocation !== "") {
      saloon = this.$appStore.getters.userLocation;
    }
    const mode = this.$appStore.getters.registerMode;
    const response = await this.$services.onboardingService.start({
      mode,
      location: saloon,
      userId: id,
    });
    localStorage.removeItem("documentData");
    const docs = await getSupportedDocs(response.supportedDocs);

    this.$appStore.actions.startOnboarding(
      response.onboardingId,
      response.registerType,
      response.lifeProofing,
      response.biometricQR,
      docs,
      id,
      user.email as string
    );
    this.$router.push({ name: RouteNames.PrivacyOnboarding });
  }

  public cancelOnboardingConfirmation() {
    this.confirmationOpened = false;
    this.userToCompleteOnboarding = {
      id: "",
      name: "",
      email: "",
    };
  }

  private searchReset() {
    this.users = [];
    this.data.bookmark = "";
    this.bookmarks = [""];
    this.data.totalPages = -1;
    this.filterdata.pageIndex = 0;
  }

  private async getUsers() {
    this.loading = true;
    this.filterdata.bookmark = this.bookmarks[this.filterdata.pageIndex - 1];
    this.data = await this.$services.userService.searchAccounts(this.filterdata);
    this.loading = false;
  }

  private restoreSearch(): void {
    if (this.$appStore.getters.filterdata) {
      this.filterdata = this.$appStore.getters.filterdata;
      this.bookmarks = this.$appStore.getters.bookmarks;
    }
  }
}
