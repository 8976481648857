import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { isAccreditationValid, QrAccreditation } from "@/core/accreditation/domain/accreditation";
import {
  DgBadge,
  DgButton,
  DgColumn,
  DgFlex,
  DgGridContainer,
  DgRow,
  DgSeparator,
  DgText,
  DgTextButton,
} from "@dasgate/uikit";
import { CredentialDate } from "@/ui/Profile/_components/CredentialDate";
import { CredentialExpiredLabel } from "@/ui/Profile/_components/CredentialExpiredLabel";
import { executorResult } from "@/ui/_utils/executor";

@Component({
  components: {
    CredentialExpiredLabel,
    DgColumn,
    DgRow,
    DgBadge,
    DgFlex,
    DgText,
    DgSeparator,
    DgGridContainer,
    DgButton,
    DgTextButton,
    CredentialDate,
  },
})
export default class QrAccreditationDetail extends Vue {
  @Prop({ required: true }) public accreditation!: QrAccreditation;

  public imageBlob = executorResult<Blob>();

  async mounted() {
    this.$execute(() => this.$container.accreditationUseCases.getImage(this.accreditation.id), {
      resultRef: this.imageBlob,
    });
  }

  get isValid() {
    return isAccreditationValid(this.accreditation);
  }

  get image() {
    return this.imageBlob.data !== undefined ? URL.createObjectURL(this.imageBlob.data) : undefined;
  }

  @Emit() onGoBack() {
    return;
  }

  @Emit() onRevoke() {
    return this.accreditation.id;
  }

  @Emit() onPrint() {
    return this.image;
  }
}
