import { DgColumn, DgGridContainer, DgGridContent, DgRow, DgText } from "@dasgate/uikit";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import { NewAccreditationCard } from "@/ui/Profile/MyAccreditations/NoAccreditations/_components/NewAccreditationCard";

@Component({ components: { DgColumn, DgRow, DgText, DgGridContainer, DgGridContent, NewAccreditationCard } })
export default class NoAccreditations extends Vue {
  @Emit() onRequestFace() {
    return;
  }

  @Emit() onRequestQr() {
    return;
  }
}
