import Component from "vue-class-component";
import { Loading } from "@/ui/_components/Loading";
import {
  DgAvatar,
  DgBox,
  DgButton,
  DgColumn,
  DgGridContainer,
  DgRow,
  DgScore,
  DgSeparator,
  DgTab,
  DgTabs,
  DgText,
  DgTextButton,
  DgWindowSizeMixin,
} from "@dasgate/uikit";
import { DataField } from "@/ui/_components/DataField";
import { UserDetailField } from "./_components/UserDetailField";
import { PersonalData } from "./_components/PersonalData";
import { PortrayalInfo } from "./_components/PortrayalInfo";
import {
  GetIamUserDetailResponse,
  GetUserCredentialsResponse,
  GetUserDetailResponse,
} from "@/legacy/features/portal/models/portal.models";
import { DocumentOcrData, OnboardingInfo, ProcessedImageData, UserImages } from "@/legacy/models/shared";
import { RouteNames } from "@/ui/_router";

@Component({
  components: {
    DgColumn,
    DgRow,
    DgText,
    DgTextButton,
    DgButton,
    DgSeparator,
    DgBox,
    DgGridContainer,
    DataField,
    UserDetailField,
    DgTab,
    DgTabs,
    DgScore,
    DgAvatar,
    PersonalData,
    PortrayalInfo,
    Loading,
  },
})
export default class UserDetail extends DgWindowSizeMixin {
  public user: GetUserDetailResponse = {} as GetUserDetailResponse;
  public iamUser: GetIamUserDetailResponse = {} as GetIamUserDetailResponse;
  public credentials: GetUserCredentialsResponse = {} as GetUserCredentialsResponse;
  public images: UserImages = {};
  public documentData: DocumentOcrData = {
    firstName: "",
    lastName: "",
    documentId: "",
    documentNumber: "",
    expiryDate: new Date(),
    birthDate: new Date(),
    expeditionCountry: "",
    nationality: "",
    documentType: "others",
  };
  public documentInfo = false;
  public imageData: ProcessedImageData = {
    selfie: -1,
    selfieOk: false,
    document: -1,
    documentOk: false,
    selfieAuthentic: false,
  };
  public register: OnboardingInfo = {
    registerId: "",
    registerType: "",
    lifeProofing: false,
    identityProofing: false,
    assistedBy: "",
  };

  public dataLoaded = false;
  private userId!: string;

  public async mounted() {
    this.userId = this.$route.params.id;
    await this.getUser(this.userId);
    await this.pullData();
  }

  public get isReceptionist(): boolean {
    return this.$appStore.getters.isReceptionist;
  }

  public get canDeleteAccountsOne(): boolean {
    return this.$appStore.getters.canDeleteAccountsOne;
  }

  public goToNewVisit() {
    this.$router.push({ name: RouteNames.NewVisit, params: { userId: this.userId } });
  }

  public goToDeleteAccount(): void {
    this.$router.push({
      name: RouteNames.DeleteAccount,
      params: { userId: this.userId, username: this.user.username },
    });
  }

  public async back(): Promise<void> {
    this.$router.push({
      name: RouteNames.PortalUsers,
      query: {
        filter: this.$route.query.filter,
        pageIndex: this.$route.query.pageIndex,
        bookmarks: this.$route.query.bookmarks,
      },
    });
  }

  private async getUser(id: string) {
    const user = await this.$services.userService.getUserDetail({
      id,
    });

    this.iamUser = await this.$services.userService.getIamUser({ userId: id });

    const credentials = await this.$services.userService.getCredentials({
      id,
    });

    const selfie = await this.$services.userService.getUserSelfie({
      id,
    });
    this.user = user;
    this.credentials = credentials;
    if (selfie !== "") {
      this.images.selfie = selfie;
    }
  }

  private async pullData(): Promise<void> {
    const id = this.userId;
    const onboardingId = this.user.onboardingId;
    const identityPortrayalId = this.user.portrayalId;

    const registerData = await this.$services.onboardingService.getUserOnboarding({
      onboardingId,
    });

    const data = await this.$services.identityPortrayalService.get({
      identityPortrayalId,
    });

    if (registerData.identityProofing) {
      const obverse = await this.$services.userService.getUserObverse({ id });
      const reverse = await this.$services.userService.getUserReverse({ id });
      if (obverse !== "") {
        this.images.obverse = obverse;
      }
      if (reverse !== "") {
        this.images.reverse = reverse;
      }
    }

    this.register = registerData;
    this.imageData = data.scores;
    this.documentData = data.ocr;
    this.documentInfo = data.documentInfo ? data.documentInfo : false;
    this.dataLoaded = true;
  }
}
