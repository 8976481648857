import { render, staticRenderFns } from "./AccessStatus.vue?vue&type=template&id=5fcece16&scoped=true&"
import script from "./AccessStatus.ts?vue&type=script&lang=ts&"
export * from "./AccessStatus.ts?vue&type=script&lang=ts&"
import style0 from "./AccessStatus.scss?vue&type=style&index=0&id=5fcece16&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fcece16",
  null
  
)

export default component.exports