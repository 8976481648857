import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DgColumn, DgIcon, DgRow, DgText, IconName, DgAvatar, DgWindowSizeMixin } from "@dasgate/uikit";

@Component({ components: { DgText, DgIcon, DgColumn, DgRow, DgAvatar } })
export default class DataField extends DgWindowSizeMixin {
  @Prop({ required: false }) public icon!: IconName;
  @Prop({ required: false }) public avatar!: string;
  @Prop({ required: true }) public title!: string;
  @Prop({ required: false }) public subtitle!: string;
}
