import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
  DgBox,
  DgButton,
  DgColumn,
  DgGridContent,
  DgModal,
  DgRow,
  DgSeparator,
  DgText,
  DgTextInput,
} from "@dasgate/uikit";
import { RouteNames } from "@/ui/_router";
import FormMixin from "@/ui/_forms/formMixin";
import { dniOrNieFormatValidator, dniOrNieLetterValidator, helpers, requiredIf } from "@/ui/_forms/validation";
import { isDefined } from "@/core/shared/utils";

@Component({
  components: {
    DgModal,
    DgSeparator,
    DgGridContent,
    DgButton,
    DgColumn,
    DgRow,
    DgText,
    DgBox,
    DgTextInput,
  },
})
export default class DeleteAccount extends FormMixin<"idNumber"> {
  @Prop({ required: true, default: "" }) public userId!: string;
  @Prop({ required: true, default: "" }) public username!: string;

  public isConfirmationModalOpen = false;
  public isErrorModalOpen = false;
  public errorMessage = "";

  initForm() {
    return {
      initialValues: {
        idNumber: "",
      },
      rules: {
        idNumber: {
          required: helpers.withMessage(
            this.$t("common.errors.required").toString(),
            requiredIf(() => this.idNumberRequired)
          ),
          dniOrNieFormat: helpers.withMessage(
            this.$t("common.errors.idcard_format").toString(),
            dniOrNieFormatValidator
          ),
          dniOrNieLetter: helpers.withMessage(
            this.$t("common.errors.idcard_letter").toString(),
            dniOrNieLetterValidator
          ),
        },
      },
    };
  }

  public get isAuto(): boolean {
    return this.$appStore.getters.registerMode === "auto";
  }

  public get idNumberRequired() {
    const askFieldForDelete = isDefined(this.$appStore.getters.settings.askFieldForDelete);
    return this.isAuto && askFieldForDelete;
  }

  public showConfirmationModal() {
    this.isConfirmationModalOpen = true;
  }

  public hideConfirmationModal() {
    this.isConfirmationModalOpen = false;
  }

  public showErrorModal() {
    this.isErrorModalOpen = true;
  }

  public hideErrorModal() {
    this.isErrorModalOpen = false;
  }

  public async deleteAccount(): Promise<void> {
    const result = await this.$services.userService.cancelUser({
      id: this.userId,
      idNumber: this.$form.model.idNumber,
    });
    this.hideConfirmationModal();
    if (result.success) {
      if (this.isAuto) {
        this.$appStore.actions.cleanAll();
        await this.$services.authentication.logout();
      } else {
        if (this.$appStore.getters.roles.includes("get_users_all")) {
          this.$router.push({ name: RouteNames.PortalUsers });
        } else {
          this.$router.push({ name: RouteNames.PortalManagement });
        }
      }
    } else {
      this.errorMessage = result.errorMessage as string;
      this.showErrorModal();
    }
  }
}
