import Component from "vue-class-component";
import {
  DgBox,
  DgButton,
  DgColumn,
  DgGridContainer,
  DgGridContent,
  DgImage,
  DgText,
  DgWindowSizeMixin,
} from "@dasgate/uikit";
import { downloadFile } from "@/ui/_utils/downlaodFile";

@Component({ components: { DgText, DgGridContainer, DgColumn, DgImage, DgGridContent, DgButton, DgBox } })
export default class AccreditProof extends DgWindowSizeMixin {
  requestProof() {
    this.$execute(this.$container.accreditationUseCases.getPdf, {
      onSuccess: pdf => {
        downloadFile(pdf, "enrolment-proof");
      },
    });
  }
}
