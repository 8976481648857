import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import {
  GetIamUserDetailResponse,
  GetUserCredentialsResponse,
  GetUserDetailResponse,
} from "@/legacy/features/portal/models/portal.models";
import { UserImages } from "@/legacy/models/shared";
import { DgAvatar, DgBox, DgColumn, DgGridContainer, DgText, DgFlex } from "@dasgate/uikit";
import { UserDetailField } from "../UserDetailField";

@Component({ components: { DgColumn, DgBox, DgGridContainer, DgText, DgAvatar, DgFlex, UserDetailField } })
export default class PersonalData extends Vue {
  @Prop({ required: true }) public user!: GetUserDetailResponse;
  @Prop({ required: true }) public images!: UserImages;
  @Prop({ required: true }) public credentials!: GetUserCredentialsResponse;
  @Prop({ required: true }) public iamUser!: GetIamUserDetailResponse;
}
