import Vue from "vue";
import Component from "vue-class-component";
import {
  DgBox,
  DgButton,
  DgColumn,
  DgDivider,
  DgFlex,
  DgGridContainer,
  DgImage,
  DgModal,
  DgRow,
  DgSeparator,
  DgText,
  DgTextButton,
} from "@dasgate/uikit";
import { DgCard } from "./_components/Card";
import { AuthPermissions, authPermissions } from "@/core/user/domain/auth";
import { RouteNames } from "@/ui/_router";

@Component({
  components: {
    DgBox,
    DgButton,
    DgTextButton,
    DgCard,
    DgColumn,
    DgDivider,
    DgFlex,
    DgGridContainer,
    DgImage,
    DgModal,
    DgRow,
    DgSeparator,
    DgText,
  },
})
export default class PortalManagement extends Vue {
  public showModal = false;

  public get permissions(): AuthPermissions {
    return authPermissions(this.$container.authRepository.get());
  }

  public get isAssistant(): boolean {
    return this.$appStore.getters.isAssistant;
  }

  public get isReceptionist(): boolean {
    return this.$appStore.getters.isReceptionist;
  }

  public get isGambling(): boolean {
    return this.$appStore.getters.settings.domain === "gambling";
  }

  public openModal() {
    this.showModal = true;
  }

  public closeModal() {
    this.showModal = false;
  }

  public async confirmCancelAll() {
    await this.cancelAll();
    this.closeModal();
  }

  public goToVisits(): void {
    this.$router.push({ name: RouteNames.VisitList });
  }

  public goToUsers(): void {
    this.$router.push({ name: RouteNames.PortalUsers });
  }

  public goToIamUsers(): void {
    this.$router.push({ name: RouteNames.PortalAccounts });
  }

  public goToCreateCredential(): void {
    this.$router.push({ name: RouteNames.SingleRegistration });
  }

  public goToCreateBatchCredential(): void {
    this.$router.push({ name: RouteNames.BatchRegistration });
  }

  public startAssistedOnboarding(): void {
    this.$router.push({ name: RouteNames.PrivacyOnboarding });
  }

  public goToActivateUsers(): void {
    this.$router.push({ name: RouteNames.ActivateUsers });
  }

  public goToCheckInterdictions(): void {
    this.$router.push({ name: RouteNames.CheckInterdictions });
  }

  public goToAuditAccesses(): void {
    this.$router.push({ name: RouteNames.AuditAccesses });
  }

  public goToUploadWatchlist(): void {
    this.$router.push({ name: RouteNames.UploadWatchlist });
  }

  public goToAssistantAccountCancellation(): void {
    this.$router.push({ name: RouteNames.AssistantAccountCancellation });
  }

  public async activateAll(): Promise<void> {
    await this.$services.tenantManagementService.activateAll();
    this.showOperationComplete();
  }

  public async deactivateAll(): Promise<void> {
    await this.$services.tenantManagementService.deactivateAll();
    this.showOperationComplete();
  }

  public async cancelAll(): Promise<void> {
    await this.$services.tenantManagementService.cancelAll();
    this.showOperationComplete();
  }

  private showOperationComplete(): void {
    this.$services.notificationService.success(this.$tc("common.completed"));
  }
}
