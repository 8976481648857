import Component from "vue-class-component";
import { VisitDetailField } from "../../Visits/_components/VisitDetailField";
import { DgBox, DgButton, DgColumn, DgModal, DgRow, DgText, DgWindowSizeMixin } from "@dasgate/uikit";
import { executorResult } from "@/ui/_utils/executor";
import { UserDetail } from "@/core/user/domain/user";
import { RouteNames } from "@/ui/_router";

@Component({
  components: {
    DgModal,
    DgButton,
    DgColumn,
    DgRow,
    DgText,
    DgBox,
    VisitDetailField,
  },
})
export default class MyProfile extends DgWindowSizeMixin {
  public userDetailResult = executorResult<UserDetail>();
  public userSelfieResult = executorResult<string>();
  public showErrorModal = false;
  public errorMessage = "";
  private showModal = false;

  public async mounted() {
    this.$execute(this.$container.userUseCases.getUserDetail, { resultRef: this.userDetailResult });
    this.$execute(this.$container.userUseCases.getUserSelfie, { resultRef: this.userSelfieResult });
  }

  public get canDeleteAccountMe(): boolean {
    return this.$appStore.getters.canDeleteAccountMe;
  }

  public get isAuto(): boolean {
    return this.$appStore.getters.registerMode === "auto";
  }

  public get userDetail() {
    return this.userDetailResult.data;
  }

  public closeErrorModal() {
    this.showErrorModal = false;
  }

  public closeModal() {
    this.showModal = false;
  }

  public async cancelRequest(): Promise<void> {
    this.showModal = true;
  }

  public async cancel(): Promise<void> {
    const result = await this.$services.userService.cancelUser({
      id: this.$appStore.getters.userId,
    });

    this.showModal = false;
    if (result.success) {
      if (this.isAuto) {
        this.$appStore.actions.cleanAll();
        await this.$services.authentication.logout();
      } else {
        this.$router.push({ name: RouteNames.PortalUsers });
      }
    } else {
      this.showErrorModal = true;
      this.errorMessage = result.errorMessage as string;
    }
  }
}
