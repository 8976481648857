import { DgColumn, DgImage, DgLoading, DgText } from "@dasgate/uikit";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({ components: { DgColumn, DgLoading, DgText, DgImage } })
export default class AccreditationsLoading extends Vue {
  @Prop({ required: true }) public title!: string;
  @Prop({ required: false }) public description?: string;
}
