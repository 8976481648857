import { DgGridContainer, DgGridContent } from "@dasgate/uikit";
import Vue from "vue";
import Component from "vue-class-component";
import { AccreditationType } from "@/core/accreditation/domain/accreditation";
import { AccreditationsLoading } from "@/ui/Profile/_components/AccreditationsLoading";

@Component({ components: { DgGridContainer, DgGridContent, AccreditationsLoading } })
export default class AccreditFace extends Vue {
  async mounted() {
    this.$execute(() => this.$container.accreditationUseCases.request(AccreditationType.Face));
  }
}
