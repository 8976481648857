import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DgColumn, DgText } from "@dasgate/uikit";

@Component({ components: { DgText, DgColumn } })
export default class CredentialDate extends Vue {
  @Prop({ required: true }) public title!: string;
  @Prop({ required: true }) public date!: Date;
  @Prop({ required: false, default: false }) public isValid!: boolean;
}
