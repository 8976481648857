import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DgColumn, DgDivider, DgText } from "@dasgate/uikit";

@Component({ components: { DgText, DgColumn, DgDivider } })
export default class VisitDetailField extends Vue {
  @Prop({ required: true }) public label!: string;
  @Prop({ required: false }) public value!: string;
  @Prop({ required: false, default: false }) public informative!: boolean;
}
