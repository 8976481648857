import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { DgColumn, DgDivider, DgScore, DgText } from "@dasgate/uikit";

@Component({ components: { DgText, DgColumn, DgScore, DgDivider } })
export default class UserDetailField extends Vue {
  @Prop({ required: true }) public label!: string;
  @Prop({ required: false }) public value!: string;
  @Prop({ required: false, default: false }) public isScore!: boolean;
  @Prop({ required: false, default: "indeterminate" }) public state!: string;
}
