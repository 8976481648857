import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { FaceAccreditation } from "@/core/accreditation/domain/accreditation";
import { DgBox, DgButton, DgColumn, DgRow, DgSeparator, DgText } from "@dasgate/uikit";
import { CredentialDate } from "@/ui/Profile/_components/CredentialDate";

@Component({ components: { DgBox, DgColumn, DgRow, DgText, DgButton, DgSeparator, CredentialDate } })
export default class FaceAccreditationCard extends Vue {
  @Prop({ required: true }) public accreditation!: FaceAccreditation;

  @Emit() onGoToDetail() {
    return this.accreditation.id;
  }
}
