import { DgBox, DgColumn, DgGridContainer, DgGridContent, DgIcon, DgRow, DgSeparator, DgText } from "@dasgate/uikit";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { FaceAccreditation, QrAccreditation } from "@/core/accreditation/domain/accreditation";
import { QrAccreditationCard } from "./_components/QrAccreditationCard";
import { FaceAccreditationCard } from "./_components/FaceAccreditationCard";
import { AccessStatus } from "./_components/AccessStatus";
import { AddCredentialBox } from "./_components/AddCredentialBox";

@Component({
  components: {
    QrAccreditationCard,
    FaceAccreditationCard,
    AccessStatus,
    AddCredentialBox,
    DgColumn,
    DgRow,
    DgText,
    DgBox,
    DgIcon,
    DgSeparator,
    DgGridContainer,
    DgGridContent,
  },
})
export default class AccreditationList extends Vue {
  @Prop({ required: true }) public hasAccess!: boolean;
  @Prop({ required: false }) public qrAccreditation?: QrAccreditation;
  @Prop({ required: false }) public faceAccreditation?: FaceAccreditation;

  @Emit() onGoToDetail(accreditationId: string) {
    return accreditationId;
  }

  @Emit() onRefresh(accreditationId: string) {
    return accreditationId;
  }

  @Emit() onRequestFace() {
    return;
  }

  @Emit() onRequestQr() {
    return;
  }
}
