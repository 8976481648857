import { Component, Prop } from "vue-property-decorator";
import {
  DgBox,
  DgButton,
  DgColumn,
  DgFlex,
  DgGridContainer,
  DgHelper,
  DgImage,
  DgSeparator,
  DgText,
  DgWindowSizeMixin,
} from "@dasgate/uikit";
import { RouteNames } from "@/ui/_router";

@Component({
  components: {
    DgFlex,
    DgText,
    DgButton,
    DgHelper,
    DgSeparator,
    DgImage,
    DgBox,
    DgColumn,
    DgGridContainer,
  },
})
export default class UploadWatchlistResult extends DgWindowSizeMixin {
  @Prop({ required: false, default: false }) public success!: boolean;

  public back() {
    this.$router.push({ name: RouteNames.UploadWatchlist });
  }
}
