import Vue from "vue";
import { Prop } from "vue-property-decorator";
import Component from "vue-class-component";
import { DgBox, DgIcon, DgRow, DgText } from "@dasgate/uikit";

@Component({ components: { DgBox, DgIcon, DgRow, DgText } })
export default class AccessStatus extends Vue {
  @Prop({ required: true }) public hasAccess?: boolean;

  get classes() {
    return [this.hasAccess && "--passing", !this.hasAccess && "--failing"];
  }
}
