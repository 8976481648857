import Vue from "vue";
import { Component } from "vue-property-decorator";
import {
  DgBox,
  DgButton,
  DgColumn,
  DgDivider,
  DgFileInput,
  DgFlex,
  DgLoading,
  DgModal,
  DgSelect,
  DgText,
  SelectOption,
} from "@dasgate/uikit";
import { RouteNames } from "@/ui/_router";

@Component({
  components: {
    DgText,
    DgColumn,
    DgBox,
    DgFlex,
    DgSelect,
    DgButton,
    DgFileInput,
    DgModal,
    DgDivider,
    DgLoading,
  },
})
export default class UploadWatchlist extends Vue {
  public onboardingFile: File = {} as File;
  public validationErrors: { [id: string]: string } = {};
  public domain = "";
  public loading = false;

  public mounted() {
    this.loading = false;
  }

  public get watchlistDomainsDropdown(): SelectOption[] {
    return this.$appStore.getters.settings.watchlistDomains.map(domain => ({
      label: this.$t("common.portal.upload-watchlist.legal_scopes." + domain) as string,
      value: domain,
    }));
  }

  public get canSubmit(): boolean {
    return !!this.onboardingFile.name && !!this.domain;
  }

  public async upload(): Promise<void> {
    this.loading = true;
    const success = await this.$services.tenantManagementService.uploadWatchlist(this.domain, this.onboardingFile);
    this.$router.push({
      name: RouteNames.UploadWatchlistResult,
      params: { success },
    });
    this.loading = false;
  }

  public onBack() {
    this.$router.push({ name: RouteNames.PortalManagement });
  }

  public setOnboardingFile(file: File) {
    this.validationErrors.metadata = "";
    this.onboardingFile = file;
  }
}
