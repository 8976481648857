import Vue from "vue";
import Component from "vue-class-component";
import { DgBox, DgColumn, DgImage, DgText } from "@dasgate/uikit";
import { Emit, Prop } from "vue-property-decorator";

@Component({ components: { DgBox, DgColumn, DgImage, DgText } })
export default class NewAccreditationCard extends Vue {
  @Prop({ required: true }) public title!: string;
  @Prop({ required: true }) public description!: string;
  @Prop({ required: true }) public image!: string;

  @Emit() onClick() {
    return;
  }
}
