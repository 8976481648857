import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { DgBox, DgSeparator, DgIcon, DgColumn, DgRow, DgText } from "@dasgate/uikit";

@Component({ components: { DgBox, DgSeparator, DgIcon, DgColumn, DgRow, DgText } })
export default class AddCredentialBox extends Vue {
  @Prop({ required: true }) public text?: string;

  @Emit() onRequest() {
    return;
  }
}
