import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { ExternalUserInfo } from "@/legacy/features/portal/models/portal.models";
import { DgBox, DgButton, DgColumn, DgGridContainer, DgRow, DgText } from "@dasgate/uikit";
import { DataField } from "@/ui/_components/DataField";

@Component({ components: { DgBox, DgGridContainer, DataField, DgRow, DgColumn, DgText, DgButton } })
export default class AccountInfoItem extends Vue {
  @Prop({ required: true }) public externalUserInfo!: ExternalUserInfo;

  @Emit() onCompleteOnboarding() {
    return;
  }
}
