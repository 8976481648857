import Component from "vue-class-component";
import { List } from "@/ui/_components/List";
import { UserInfoItem } from "./_components/UserInfoItem";
import { GetUsersRequest, UserInfo } from "@/legacy/features/portal/models/portal.models";
import { DgBox, DgColumn, DgGridContainer, DgSearch, DgText, DgWindowSizeMixin } from "@dasgate/uikit";
import { PagedResult } from "@/legacy/models/shared";
import { RouteNames } from "@/ui/_router";

@Component({
  components: {
    UserInfoItem,
    List,
    DgGridContainer,
    DgColumn,
    DgBox,
    DgText,
    DgSearch,
  },
})
export default class PortalUsers extends DgWindowSizeMixin {
  public selfies: Record<string, string> = {};
  public users: UserInfo[] = [];
  public filterdata = {
    filter: "",
    pageSize: 10,
    pageIndex: 1,
    bookmark: "",
    orderby: "date_register",
    sorted: "DESC",
  } as GetUsersRequest;

  public data: PagedResult<UserInfo> = {
    bookmark: "",
    pageSize: 10,
    totalPages: -1,
    totalItems: -1,
    items: [],
  };
  public bookmarks = [""];
  public awaitingSearch = false;
  public loading = false;

  public async mounted() {
    this.restoreSearch();
    await this.getUsers();
  }

  public get hasMoreItems(): boolean {
    return this.filterdata.pageIndex < this.data.totalPages;
  }

  public get hasLessItems(): boolean {
    return this.filterdata.pageIndex > 1;
  }

  public get isFirstPage(): boolean {
    return this.filterdata.pageIndex === 1;
  }

  public async toggleState(user: UserInfo): Promise<void> {
    if (user.active) {
      await this.$services.userService.deactivateCredential({
        userId: user.id,
      });
    } else {
      await this.$services.userService.activateCredential({
        userId: user.id,
      });
    }

    this.getUsers();
  }

  public nextPage(): void {
    if (this.hasMoreItems) {
      this.filterdata.pageIndex = this.filterdata.pageIndex + 1;
      this.getUsers();
    }
  }

  public backPage(): void {
    if (this.hasLessItems) {
      this.filterdata.pageIndex = this.filterdata.pageIndex - 1;
      this.getUsers();
    }
  }

  public firstPage(): void {
    if (!this.isFirstPage) {
      this.filterdata.pageIndex = 1;
      this.getUsers();
    }
  }

  public search() {
    if (!this.awaitingSearch) {
      setTimeout(() => {
        this.searchReset();
        this.getUsers();
        this.awaitingSearch = false;
      }, 500);
    }
    this.awaitingSearch = true;
  }

  public goToDetail(id: string) {
    this.saveSearch();
    this.$router.push({
      name: RouteNames.PortalUserDetail,
      params: { id },
    });
  }

  private searchReset() {
    this.users = [];
    this.data.bookmark = "";
    this.bookmarks = [""];
    this.data.totalPages = -1;
    this.filterdata.pageIndex = 1;
  }

  private saveSearch(): void {
    this.$appStore.actions.saveUserSearch(this.filterdata, this.bookmarks);
  }

  private restoreSearch(): void {
    if (this.$appStore.getters.filterdata) {
      this.filterdata = this.$appStore.getters.filterdata;
      this.bookmarks = this.$appStore.getters.bookmarks;
    }
  }

  private async getUsers() {
    this.loading = true;
    this.filterdata.bookmark = this.bookmarks[this.filterdata.pageIndex - 1];
    this.data = await this.$services.userService.getUsers(this.filterdata);
    this.bookmarks[this.filterdata.pageIndex] = this.data.bookmark;
    const selfies = await Promise.all(
      this.data.items.map(userInfo => {
        return this.$services.userService.getUserSelfie({ id: userInfo.id });
      })
    );

    selfies.forEach((selfie, index) => {
      this.selfies = { ...this.selfies, [this.data.items[index].id]: selfie };
    });

    this.loading = false;
  }
}
