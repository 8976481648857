import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import { UserInfo } from "@/legacy/features/portal/models/portal.models";
import { DgBox, DgGridContainer, DgToggle } from "@dasgate/uikit";
import { DataField } from "@/ui/_components/DataField";
import moment from "moment";
import i18n from "@/legacy/infrastructure/i18n";

@Component({
  components: {
    DgBox,
    DgGridContainer,
    DgToggle,
    DataField,
  },
})
export default class UserInfoItem extends Vue {
  @Prop({ required: true }) public userInfo!: UserInfo;
  @Prop({ required: false }) public avatar!: string;

  @Emit() onClick() {
    return;
  }

  @Emit() onToggle() {
    return;
  }

  get clickable() {
    return this.$listeners && this.$listeners["on-click"];
  }

  get classes() {
    return [this.userInfo.active ? "--active" : "--disabled", this.clickable && "--clickable"];
  }

  public toDateString(date: Date): string {
    return moment(date)
      .utc()
      .format(i18n.t("tenant.dateFormat") as string);
  }

  public toTimeString(date: Date): string {
    return moment(date)
      .utc()
      .format(i18n.t("tenant.timeFormat") as string);
  }
}
