import Vue from "vue";
import Component from "vue-class-component";
import { DgGridContainer, DgMarkdownText, DgModal } from "@dasgate/uikit";
import {
  Accreditation,
  AccreditationType,
  FaceAccreditation,
  getAccreditationByType,
  isAccreditationValid,
  QrAccreditation,
} from "@/core/accreditation/domain/accreditation";
import { RouteNames } from "@/ui/_router";
import { isDefined, isEmpty } from "@/core/shared/utils";
import { NoAccreditations } from "./NoAccreditations";
import { AccreditationList } from "./AccreditationList";
import { MyAccreditationsLoading } from "./MyAccreditationsLoading";
import { executorResult } from "@/ui/_utils/executor";

@Component({
  components: {
    AccreditationList,
    NoAccreditations,
    MyAccreditationsLoading,
    DgModal,
    DgMarkdownText,
    DgGridContainer,
  },
})
export default class MyAccreditations extends Vue {
  public isModalOpen = false;
  public htmlTermsResult = executorResult<string>();
  public accreditationsResult = executorResult<Accreditation[]>([]);

  async mounted() {
    await this.getAccreditations();

    this.$watch("$i18n.locale", this.getTerms);
    await this.getTerms();
  }

  get hasAccreditations() {
    return !isEmpty(this.accreditationsResult.data);
  }

  get hasAccess() {
    return isDefined(this.accreditationsResult.data.find(isAccreditationValid));
  }

  get qrAccreditation(): QrAccreditation | undefined {
    return (
      this.accreditationsResult.data && getAccreditationByType(this.accreditationsResult.data, AccreditationType.Qr)
    );
  }

  get faceAccreditation(): FaceAccreditation | undefined {
    return (
      this.accreditationsResult.data && getAccreditationByType(this.accreditationsResult.data, AccreditationType.Face)
    );
  }

  public showModal() {
    this.isModalOpen = true;
  }

  public closeModal() {
    this.isModalOpen = false;
  }

  public goToDetail(accreditationId: string) {
    this.$router.push({ name: RouteNames.AccreditationDetail, params: { accreditationId } });
  }

  public async requestQr() {
    this.$execute(() => this.$container.accreditationUseCases.request(AccreditationType.Qr), {
      fallbackLocation: accreditation => ({
        name: RouteNames.AccreditationDetail,
        params: { accreditationId: accreditation.id },
      }),
    });
  }

  public async requestFace() {
    this.$execute(() => this.$container.accreditationUseCases.request(AccreditationType.Face), {
      fallbackLocation: accreditation => ({
        name: RouteNames.AccreditationDetail,
        params: { accreditationId: accreditation.id },
      }),
      onError: () => this.$router.push({ name: RouteNames.RegisterSelfie }),
    });
  }

  public async refresh(accreditationId: string) {
    await this.$execute(() => this.$container.accreditationUseCases.refresh(accreditationId));
  }

  private async getAccreditations() {
    await this.$execute(() => this.$container.accreditationUseCases.getAll(), {
      resultRef: this.accreditationsResult,
    });
  }

  private async getTerms() {
    await this.$execute(() => this.$services.onboardingService.getTermsOfUse(this.$i18n.locale), {
      resultRef: this.htmlTermsResult,
    });
  }
}
